import { useDisclosure } from '@/hooks';
import { cn } from '@/lib/utils';
import { forwardRef, useImperativeHandle, useState, useRef } from 'react';
import { usePage } from '@inertiajs/react';
import Heading from '@/components/atoms/heading';
import Button from '@/components/atoms/button';
import Modal from '@/components/molecules/modal';
import Miniature from '@/components/molecules/miniature';
import LogoModalImg from '@/images/logo-modal-bg.png';
import { INSURANCE_TYPES, INSURANCE_IMAGES } from '@/lib/utils/insuranceTypes';

const GetQuoteModal = forwardRef(({ client }, ref) => {
	const { policyTypes } = usePage().props;
	const { isOpen, onOpen, onClose } = useDisclosure(false);
	const updatedPolicyTypes = policyTypes?.map((pt) => ({ ...pt, imgSrc: INSURANCE_IMAGES[pt.uri], themeColor: INSURANCE_TYPES[pt.uri] }));

	useImperativeHandle(
		ref,
		() => {
			return {
				onOpen: onOpen,
			};
		},
		[onOpen]
	);

	return (
		<Modal
			open={isOpen}
			onOpenChange={() => {
				if (isOpen) {
					onClose();
				}
			}}
			hideFooter
			contentClasses="px-6 md:px-8 !max-w-[100vw] w-full rounded-none h-full max-h-none bg-corporateBlueLight1 border-0 shadow-none"
			overlayClasses="bg-black/80"
			closeBtnText="Close"
			customCloseBtnComponent={() => (
				<Button
					variant="icon-and-underline"
					size="none"
					iconRight="close"
					iconAndUnderline="icon-and-underline"
					className="text-eis-black"
					iconContainerClassName="bg-transparent text-corporateBlueBright group-hover:bg-eis-light-blue-1 active:bg-formOutline focus-visible:bg-formOutline rounded-full w-10 h-10 flex items-center justify-center"
					onClick={() => {
						onClose();
					}}
				>
					Close
				</Button>
			)}
		>
			<div className="flex flex-col items-center space-y-8">
				<div className="hidden sm:block absolute left-eis-30 -top-12 h-[1065px] w-[1600px] -z-[1]">
					<img src={LogoModalImg} alt="Logo modal background" className="block object-contain w-full max-w-full max-h-full" />
				</div>
				<Heading className="mb-6 text-corporateBlue" type="h3">
					Available policies
				</Heading>
				<div className="flex flex-wrap max-w-3xl gap-6 sm:-mx-3 sm:gap-x-0 xl:px-12">
					{updatedPolicyTypes?.map((policyType, idx) => (
						<Miniature
							key={`${idx}-${policyType.uri}`}
							clientId={client ? client.id : null}
							miniature={policyType}
							className="w-full sm:w-[calc(50%-1.5rem)] md:w-[calc(50%-1.5rem)] xl:w-[calc(50%-1.5rem)]"
						/>
					))}
				</div>
			</div>
		</Modal>
	);
});

export default GetQuoteModal;
